jQuery(document).ready(function ($) {

	// Mobile menu hamburger
	$(('.hamburger')).click(function(event) {
		let openButton = $(this).children('.open');
		let closeButton = $(this).children('.closed');

		if (openButton.is(":visible")) {
			$('.menu-main-container').slideDown();
			openButton.hide();
			closeButton.show();
		} else {
			$('.menu-main-container').slideUp();
			closeButton.hide();
			openButton.show();
		}
	});

	// Home page photo slick slider
	$('.photo-slider').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/smokininthepines/dist/images/icon-chevron.png" alt="Previous" /></button>',
		nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/smokininthepines/dist/images/icon-chevron.png" alt="Next" /></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});

});